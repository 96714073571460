@import "~antd/dist/antd.min.css";

body {
    margin: 0;
    padding: 0;
}

body, #root {
    max-height: 100%;
}

#root {
    display: flex;
}